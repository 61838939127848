const FLASHLOAN_FEE = 0.1;

export const calculateAutoCompoundValues = ({
  positionApr,
  borrowed: initBorrowed,
  positionValue: initPositionValue,
  compoundGasCost,
  compoundCostCap,
}: {
  positionApr?: number;
  borrowed?: number;
  positionValue?: number;
  compoundGasCost?: number;
  compoundCostCap?: number;
}) => {

  if (!positionApr || !initBorrowed || !initPositionValue || !compoundGasCost || !compoundCostCap) {
    return {
      apr: 0,
      compounds: 0,
      compoundCost: 0,
    }
  }

  let borrowed = initBorrowed;
  let positionValue = initPositionValue;

  let accruedRewards = 0;
  let compounds = 0;
  let firstCompoundCost = 0;

  const initNetWorth = initPositionValue - initBorrowed;

  for(let i = 0; i < 365 * 24; i++) {
    const netWorth = positionValue - borrowed;
    accruedRewards += (positionApr * 100) * netWorth / (365 * 24 * 100);

    let compoundCost = borrowed * FLASHLOAN_FEE / 100 + compoundGasCost;
    const maxCompoundFee = accruedRewards * compoundCostCap / 100;

    if (compoundCost <= maxCompoundFee) {
      compoundCost = maxCompoundFee;
      if (!firstCompoundCost) firstCompoundCost = compoundCost;
      positionValue += accruedRewards;
      borrowed += compoundCost;
      accruedRewards = 0;
      compounds += 1;
    }
  }

  const apr = (positionValue + accruedRewards - borrowed - initNetWorth) / initNetWorth * 100;

  return {
    apr,
    compounds,
    compoundCost: firstCompoundCost,
  }
};
