import { useQuery } from '@tanstack/react-query';
import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { IAlmPoolApr } from '../../types/pools';
import { useProtocolDataContext } from '../useProtocolDataContext';

export interface ISteerPoolAprRequestParams {
  vaultAddress?: string;
  chainId: number;
}

export interface ISteerPoolAprResponse {
  apr: {
    apr: number;
    message?: string;
  },
  vaultAddress: string;
}

export const getSteerPoolsApr = async ({ vaultAddress, chainId }: ISteerPoolAprRequestParams) => {
  try {
    const url = `https://api.steer.finance/pool/fee-apr?address=${vaultAddress}&chain=${chainId}&interval=604800`;
    const result = await fetch(url);
    const json: ISteerPoolAprResponse = await result.json();
    return json;
  } catch (e) {
    return null;
  }
};

const INITIAL_DATA: IAlmPoolApr = {
  apr: 0,
  vaultAddress: '',
}

export const useSteerPoolAprQuery = ({ vaultAddress, enabled }: { vaultAddress?: string; enabled?: boolean; }) => {
  const { currentChainId } = useProtocolDataContext();

  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.STEER_POOL_APR, currentChainId, vaultAddress],
    queryFn: () => getSteerPoolsApr({
      chainId: currentChainId,
      vaultAddress,
    }),
    select: (response): IAlmPoolApr => ({
      vaultAddress: vaultAddress || '',
      apr: response?.apr.apr || 0,
    }),
    enabled: enabled && Boolean(currentChainId && vaultAddress),
    refetchInterval: POLLING_INTERVAL,
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  };
};
