import BigNumber from 'bignumber.js';
import { useQuery } from '@tanstack/react-query';
import { toChecksumAddress } from 'ethereum-checksum-address';
import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { IAlmPoolApr } from '../../types/pools';
import { useProtocolDataContext } from '../useProtocolDataContext';

export type TGammaPoolAprResponse = Record<string, {
  feeApr: number;
  feeApy: number;
  status: string;
  symbol: string;
}>;

export const getGammaPoolsApr = async () => {
  try {
    const url = `https://wire2.gamma.xyz/quickswap/polygon/hypervisors/feeReturns/daily`;
    const result = await fetch(url);
    const json: TGammaPoolAprResponse = await result.json();
    return json;
  } catch (e) {
    return {};
  }
};

const INITIAL_DATA: IAlmPoolApr = {
  apr: 0,
  vaultAddress: '',
}

export const useGammaPoolAprQuery = ({ vaultAddress, enabled = true }: { vaultAddress?: string; enabled?: boolean; }) => {
  const { currentChainId } = useProtocolDataContext();

  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.GAMMA_POOL_APR, currentChainId, vaultAddress],
    queryFn: getGammaPoolsApr,
    select: (response): IAlmPoolApr => {
      const feeApy = Object.entries(response).find(([address]) => {
        return toChecksumAddress(address) === toChecksumAddress(vaultAddress || '');
      })?.[1].feeApy;
      return {
        vaultAddress: vaultAddress || '',
        apr: BigNumber(feeApy || 0).multipliedBy(100).toNumber(),
      }
    },
    enabled: enabled && Boolean(currentChainId && vaultAddress),
    refetchInterval: POLLING_INTERVAL,
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  };
};
