import { EStrategyProvider } from './useAlmPoolQuery';
import { useSteerPoolAprQuery } from './useSteerPoolAprQuery';
import { useGammaPoolAprQuery } from './useGammaPoolAprQuery';

export const useAlmPoolAprQuery = ({ strategyProvider, poolAddress }: { strategyProvider?: EStrategyProvider, poolAddress?: string; }) => {
  const { data: steerPoolAprData, ...restSteerRequestData} = useSteerPoolAprQuery({
    vaultAddress: poolAddress,
    enabled: strategyProvider === EStrategyProvider.Steer,
  });

  const { data: gammaPoolAprData, ...restGammaRequestData} = useGammaPoolAprQuery({
    vaultAddress: poolAddress,
    enabled: strategyProvider === EStrategyProvider.Gamma,
  });

  switch (strategyProvider) {
    case EStrategyProvider.Steer:
      return {
        data: steerPoolAprData,
        ...restSteerRequestData,
      }
    case EStrategyProvider.Gamma:
      return {
        data: gammaPoolAprData,
        ...restGammaRequestData,
      }
    default:
      return {
        data: {
          apr: 0,
          vaultAddress: '',
        },
        isLoading: false,
      }
  }
};
