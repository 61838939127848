import { ComputedReserveData } from '../hooks/app-data-provider/useAppDataProvider';
import { IEnrichedUniswapPosition } from '../types/uniswapTokens';
import { getAmountToBorrow } from './getAmountToBorrow';
import { getBorrowApyByAmount } from './getBorrowApyByAmount';

export const getBorrowApyByLeverage = (
  leverage: number,
  enrichedPosition?: IEnrichedUniswapPosition,
  tokenToBorrow?: ComputedReserveData,
) => {
  const amountToBorrow = getAmountToBorrow(enrichedPosition, tokenToBorrow, leverage);
  return getBorrowApyByAmount({ amountToBorrow, tokenToBorrow });
}
